import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Navbar from './container/NavBar';
// import FavouriteManager from './container/FavouriteManager';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { convertNullToUndefined, generateSEO, updateCanonicalTag } from './helpers';
// import { fetchAllData } from './graphql';

const HomePage = React.lazy(() => import('./container/Home'));
const AboutPage = React.lazy(() => import('./container/About'));
const ThingsToDoPage = React.lazy(() => import('./container/ThingsToDo'));
const SpecialsPage = React.lazy(() => import('./container/Specials'));
const PackagesPage = React.lazy(() => import('./container/Packages'));
const DiningPage = React.lazy(() => import('./container/Dining'));
const RestaurantPage = React.lazy(() => import('./container/Restaurant'));
const RoomsPage = React.lazy(() => import('./container/Rooms'));
const GalleryPage = React.lazy(() => import('./container/Gallery'));
const MeetingsPage = React.lazy(() => import('./container/Meetings'));
const NestedPage = React.lazy(() => import('./container/NestedPage'));
const PoliciesPage = React.lazy(() => import('./container/Policies'));
const PressPage = React.lazy(() => import('./container/Press'));
const BlogPage = React.lazy(() => import('./container/Blog'));
const MovieMakerPage = React.lazy(() => import('./container/MovieMaker'));

function App({ history }) {
  const [data, setPageData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/all`)
      .then(response => response.json())
      .then(data => {
        setPageData(convertNullToUndefined(data));
      }).catch(error => {
        // @todo handle error
        console.log('Something went wrong');
      });

    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  if (!data) {
    return <Loader></Loader>
  }

  const { Rooms, Home, ThingsToDo = {}, About = {}, Specials, Policies, CoreData, Navigation, PointsOfInterest, Gallery, Dining, Restaurant, Meetings, Press, AmpLMS, AmpRooms, AmpPackages,MovieMaker } = data;
  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        {generateSEO(Home.SEO)}
        <Navbar cmsData={{ Navigation, CoreData }} />
        <Switch>
          <Route exact path="/rooms" component={() => <>
            {generateSEO(Rooms.SEO)}
            <RoomsPage cmsData={Rooms} AmpRooms={AmpRooms} Policies={Policies} />
          </>} />
          <Route exact path="/thingstodo" component={() => <>
            {generateSEO(ThingsToDo.SEO)}
            <ThingsToDoPage cmsData={ThingsToDo} pointsOfInterestCmsData={PointsOfInterest} />
          </>} />
          <Route exact path="/dining" component={() => <>
            {generateSEO(Dining.SEO)}
            <DiningPage cmsData={Dining} />
          </>} />
          <Route exact path="/restaurants" component={() =>
            <>
              {generateSEO(Restaurant.SEO)}
              <RestaurantPage cmsData={Restaurant} />
            </>} />
          <Route exact path="/about" component={() => <>
            {generateSEO(About.SEO)}
            <AboutPage cmsData={About} />
          </>} />
          <Route exact path="/specials" component={() =>
            <>
              {generateSEO(Specials.SEO)}
              <SpecialsPage cmsData={Specials} AmpLMS={AmpLMS} />
            </>} />
          <Route exact path="/packages" component={() =>
            <>
              {generateSEO(Specials.SEO)}
              <PackagesPage cmsData={Specials} AmpPackages={AmpPackages} />
            </>} />
          <Route exact path="/meetings" component={() => <MeetingsPage cmsData={Meetings} AmpPackages={AmpPackages} />} />
          {/* <Route exact path="/48hours" component={() => <FortyEightHoursPage cmsData={FortyEightHours} />} /> */}
          <Route exact path="/gallery" component={() => <GalleryPage cmsData={Gallery} />} />
          <Route exact path="/press" component={() => <PressPage homeCmsData={Home} cmsData={Press} />} />
          <Route exact path="/policies" component={() => <PoliciesPage carouselCmsData={About} cmsData={Policies} />} />
          <Route exact path="/movie-maker" component={() => <MovieMakerPage cmsData={MovieMaker} />} />
          <Route path="/nested/:name" component={NestedPage} />
          <Route path="/blog" component={BlogPage} />
          <Route path="/hot-springs-bed-and-breakfast.html"><Redirect to="/rooms" /></Route>
          <Route path="/hot-springs-vacation-packages.html"><Redirect to="/specials" /></Route>
          <Route path="/hot-springs-inn-food.html"><Redirect to="/dining" /></Route>
          <Route path="/things-to-do-in-hot-springs-arkansas.html"><Redirect to="/thingstodo" /></Route>
          <Route path="/map-and-directions.html"><Redirect to="/about" /></Route>
          <Route path="/hot-springs-hotel-photo-gallery.html"><Redirect to="/gallery" /></Route>
          <Route path="/in-the-news.html"><Redirect to="/press" /></Route>
          <Route path="/arkansas-retreats-and-meetings.html"><Redirect to="/meetings" /></Route>
          <Route path="/things-to-do-in-hot-springs-arkansas.html"><Redirect to="/thingstodo" /></Route>
          <Route path="/arkansas-travel-blog"><Redirect to="/blog" /></Route>
          <Route path="/" component={() =>
            <>
              <HomePage cmsData={Home} />
            </>} />
        </Switch>
        <Footer cmsData={CoreData} />
        {/* <FavouriteManager CoreData={CoreData} Rooms={Rooms.Rooms} LMS={Specials.LastMinuteSpecials} Packages={Specials.Promotions} /> */}
      </div>
    </Suspense>
  );
}

export default withRouter(App);
